import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import { Feature } from '../components/feature';
import { Logo, LogoPiart, LogoPolicy, LogoSK, LogoSKVertical, LogoSNLab, LogoVertical } from '../components/logo';
import {
  MenuIcon,
  MenuCloseIcon,
  CommLabIcon,
  LinkedIcon,
  MailIcon,
  DownIcon,
  FbIcon,
  AtIcon,
  PhoneIcon,
  MapIcon,
  PlusIcon,
  DocsIcon,
} from '../components/icons';
import { start } from '../components/snow';
import { throttle } from '../components/utils';

import '../styles/style.scss';
import { partners } from '../components/partners';
import { references } from '../components/references';

function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: true,
  };
  const opt = {
    ...defaultOptions,
    ...options,
  };
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
  if (!browserLocales) {
    return undefined;
  }
  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}

function scrollToTargetAdjusted(element) {
  const headerOffset = 50;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}

// data
const menu = [
  {
    title: 'Home',
    link: '/case_studies/',
  },
  {
    title: 'References',
    link: '/references',
  },
  {
    title: 'Management',
    link: '/management',
  },
  {
    title: 'Contacts',
    link: '/contacts',
  },
];

// Public Affairs - Pa (91)
// Social Media - Sm (62)
// Public relations - Pr (59)
// Grafic design - Gd (64)
// Crisis communication - C (6)
// Events - Es (99)
// Internal communication - I (53)
// Strategic communication - Sc (21)
// Foto/video - F(9)
// Websites - W(74)
// Podcasts - P (15)
// Influencers - In (49)
// Policy PR (Po 84)
// Media training - Mt (109)

const features = [
  {
    title: 'Public relations',
    symbol: 'Pr',
    number: '59',
    size: 'lg',
  },
  {
    title: 'Social Media',
    symbol: 'Sm',
    number: '62',
    size: 'sm',
  },
  {
    title: 'Media training',
    symbol: 'Mt',
    number: '109',
    size: 'sm',
  },
  {
    title: 'Crisis communication',
    symbol: 'C',
    number: '19',
    size: 'mid',
  },
  {
    title: 'Events',
    symbol: 'Es',
    number: '99',
    size: 'sm',
  },
  {
    title: 'Internal communication',
    symbol: 'I',
    number: '53',
    size: 'mid',
  },
  {
    title: 'Policy PR',
    symbol: 'Pi',
    number: '84',
    size: 'sm',
  },
  {
    title: 'Strategic communication',
    symbol: 'Sk',
    number: '64',
    size: 'sm',
  },
  {
    title: 'Public affairs',
    symbol: 'Pa',
    number: '91',
    size: 'sm',
  },
  {
    title: 'Graphic design',
    symbol: 'Gd',
    number: '65',
    size: 'sm',
  },
  {
    title: 'Photo/video',
    symbol: 'F',
    number: '9',
    size: 'sm',
  },
  {
    title: 'Websites',
    symbol: 'W',
    number: '74',
    size: 'sm',
  },
  {
    title: 'Podcasts',
    symbol: 'P',
    number: '15',
    size: 'sm',
  },
  {
    title: 'Influencers',
    symbol: 'In',
    number: '49',
    size: 'sm',
  },
];

const caseStudies = [
  {
    links: [
      {
        title: 'Case study',
        link: '/case_studies/D.A.S. právní ochrana_CZ.pdf',
      },
    ],
    title: 'D.A.S. legal protection',
    logo: '/references/das.png',
  },
  {
    links: [
      {
        title: 'Case study',
        link: '/Wolt_CZ.pdf',
      },
    ],
    title: 'Wolt',
    logo: '',
  },
  {
    links: [
      {
        title: 'Case study',
        link: '/case_studies/Halla_CZ.pdf',
      },
    ],
    title: 'Halla',
    logo: '',
  },
  {
    links: [
      {
        title: 'Business makeovers',
        link: '/case_studies/Meta_CZ_Business_makeovers.pdf',
      },
      {
        title: 'Creators of Tomorrow',
        link: '/case_studies/Meta_CZ_Creators_of_Tomorrow.pdf',
      },
      {
        title: 'Moje místo',
        link: '/case_studies/Meta_CZ_Moje_místo.pdf',
      },
    ],
    title: 'Meta',
    logo: '',
  },
  {
    links: [
      {
        title: 'Suppliers day',
        link: '/case_studies/WEC_CZ.pdf',
      },
      {
        title: 'Profilace',
        link: '/case_studies/WEC_CZ_profilace.pdf',
      },
    ],
    title: 'Westinghouse',
    logo: '',
  },
  {
    links: [
      {
        title: 'Case study',
        link: '/case_studies/GDELS_CZ.pdf',
      },
    ],
    title: 'GDELS',
    logo: '',
  },
  {
    links: [
      {
        title: 'Case study',
        link: '/case_studies/Moon50_CZ.pdf',
      },
    ],
    title: 'Moon50',
    logo: '',
  },
  {
    links: [
      {
        title: 'Case study',
        link: '/case_studies/ES_CZ.pdf',
      },
    ],
    title: 'Ekonomické stavby',
    logo: '',
  },
];

const Menu = ({ visible, toggle, toggleVisible, scrolling, aboutRef, referencesRef, contactRef }) => {
  return (
    <>
      <MenuIcon
        onClick={() => {
          toggle(false);
          toggleVisible(true);
          console.log('Toggle menu', visible);
        }}
      />
      {scrolling && (
        <nav className={`menu ${scrolling ? 'visible' : ''}`}>
          <div className="menu-logo">
            <Logo alt="Commlab" />
            <LogoVertical alt="Commlab" />
          </div>
          <ul className={`${visible ? 'visible-items' : ''}`}>
            <li
              className="grow2"
              onClick={() => {
                scrollToTargetAdjusted(aboutRef.current);
                toggle(false);
                toggleVisible(false);
                // aboutRef.current.scrollIntoView();
              }}
            >
              About
            </li>
            <li
              className="grow2"
              onClick={() => {
                scrollToTargetAdjusted(referencesRef.current);
                toggle(false);
                toggleVisible(false);
                // referencesRef.current.scrollIntoView();
              }}
            >
              References
            </li>
            <li
              className="grow2"
              onClick={() => {
                scrollToTargetAdjusted(contactRef.current);
                toggle(false);
                toggleVisible(false);
                // contactRef.current.scrollIntoView();
              }}
            >
              Contact
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

const Li = ({ href }) => {
  return (
    <Link target="_blank" className="icon linked grow" href={href}>
      <LinkedIcon />
    </Link>
  );
};

const Mail = ({ href }) => {
  return (
    <Link target="_blank" className="icon mail grow" href={href}>
      <MailIcon />
    </Link>
  );
};

const availableLangs = ['en', 'cs'];

// markup
const IndexPage = () => {
  const aboutRef = useRef(null);
  const referencesRef = useRef(null);
  const contactRef = useRef(null);
  const [lang, toggleLang] = useState('cs');
  const [scrolling, setScrolling] = useState(false);
  const [expandRef, setExpandRef] = useState(false);
  const [started, setStarted] = useState(false);
  // const [scrollTop, setScrollTop] = useState(0);
  const [visible, toggle] = useState(false);

  useEffect(() => {
    function onScroll() {
      if (visible) {
        return;
      }
      console.log('on scroll');
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      if (currentPosition > 100) {
        // downscroll code
        if (!scrolling) {
          setScrolling(true);
          console.log('on scroll over');
        }
      } else {
        // upscroll code
        if (scrolling) {
          setScrolling(false);
          console.log('on scroll below');
        }
      }
      // setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    const throttledOnSroll = throttle(onScroll, 300);

    window.addEventListener('scroll', throttledOnSroll);

    return () => window.removeEventListener('scroll', throttledOnSroll);
  });

  useEffect(() => {
    // Update the document title using the browser API
    const locales = getBrowserLocales();
    // Start animation
    if (!started) {
      start();
      setStarted(true);
    }

    // if (lang === locales[0]) { //
    //   return;
    // } else if (locales[0] === 'en') {
    //   window.location.href = '/en';
    // }
    //
  });

  return (
    <>
      <Helmet>
        <script src="//cdn.cookie-script.com/s/0b1a573414b0531f46d61fed9cf8d65c.js" />
      </Helmet>
      <main className={`${scrolling ? 'scrolled' : ''}`}>
        <div id="snow"></div>
        <Menu
          toggle={(next) => {
            setScrolling(!next);
          }}
          toggleVisible={(next) => {
            toggle(next);
          }}
          visible={visible}
          scrolling={scrolling}
          aboutRef={aboutRef}
          referencesRef={referencesRef}
          contactRef={contactRef}
        />
        <section ref={aboutRef} className="section landing">
          <article>
            <h1>
              <Logo alt="Commlab" />
            </h1>
            <p>
              We are a public relations and public affairs agency.
              <br />
              We operate in Prague, Brno and Bratislava.
            </p>
          </article>
          {!scrolling && <DownIcon className="down-icon bounce" />}
        </section>
        <section className="commlab-group">
          <article>
            <Container>
              <Row>
                <Col md={12} lg={6} className="cl-left">
                  <p>
                    Since 2015 we have been helping global corporations, Czech as well as Slovak companies and
                    individuals to pursue their interests in the public space. Collectively, we have decades of
                    experience working at all levels, from international to local. We operate to the highest
                    professional and ethical standards.
                  </p>
                </Col>
                <Col md={12} lg={6} className="cl-right">
                  <p>
                    We focus on clients in the technology, industrial, development, design and healthcare sectors. We
                    aim to become a business partner to our clients and to help them continue to grow and strengthen
                    their position in the market. Therefore, our aim is always to find individual and creative solutions
                    tailor-made to the specific projects. The client's interests are the alpha and omega of our work.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <br />
                  <p>
                    <em>We are delighted to be able to substantiate this with 13 PR and communications awards.</em>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul className="awards">
                    <li>
                      <img src="/lemuri_trans.png" />
                    </li>
                    <li>
                      <img src="/strednik.png" />
                    </li>
                    <li>
                      <img src="/lupa.png" />
                    </li>
                    <li>
                      <img src="/podcast_roku_trans.png" />
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </article>
          <h2>Elements in our laboratory</h2>
          <ul className="group-logos">
            <li>
              <a href="https://commlab.sk/">
                <LogoSK />
              </a>
            </li>
          </ul>
          <ul className="group-logos-2">
            <li className="piart">
              <a href="https://piartlab.cz/">
                <LogoPiart fill="white" />
              </a>
            </li>
            <li className="policy">
              <a href="https://policylab.cz/">
                <LogoPolicy fill="white" />
              </a>
            </li>
            <li className="snlab">
              <a href="https://socialnetworkslab.cz">
                <LogoSNLab fill="white" />
              </a>
            </li>
          </ul>
        </section>
        <section className="features">
          <div className="wrap" style={{}}>
            {features.map(({ title, symbol, number, size }, i) => (
              <Feature
                key={i}
                title={title}
                symbol={symbol}
                number={number}
                className={`feature-${number}`}
                size={size}
              />
            ))}
          </div>
        </section>
      </main>
      <section ref={referencesRef} className="references">
        <h2>References</h2>
        <p>More than 100 companies from all over the world have already made use of our services.</p>
        <Container>
          <Row>
            <Col>
              <ul>
                {[...references.slice(0, 7)].map((ref, i) => (
                  <li key={i} className="reference">
                    <img src={ref.link} alt={ref.title} />
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col className="more-ref-container">
              <ul className={expandRef ? 'more-ref visible' : 'more-ref'}>
                {[...references.slice(7, references.length)].map((ref, i) => (
                  <li key={i} className="reference">
                    <img src={ref.link} alt={ref.title} />
                  </li>
                ))}
              </ul>
              <div className={expandRef ? 'expanded' : ''}>
                <PlusIcon onClick={() => setExpandRef(!expandRef)} />
              </div>
            </Col>
          </Row>
        </Container>
        <h2>Case studies</h2>
        <Container>
          <Row>
            {caseStudies.map((item, i) => (
              <Col lg={3} sm={12} key={i}>
                <div className="case-study">
                  <h4>{item.title}</h4>
                  <ul>
                    {item.links.map((item, i) => {
                      return (
                        <li>
                          <DocsIcon />
                          &nbsp;
                          <a target="_blank" href={item.link}>
                            {item.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <Container></Container>
      </section>
      <section ref={contactRef} className="leads">
        <h2>Management</h2>
        <Container>
          <Row>
            <Col md={4}>
              <div className="lead">
                <div className="frame">
                  <Li href="https://cz.linkedin.com/in/michaela-studena-92513247" />
                  <Mail href="mailto:michaela@commlab.cz" />
                  <img src="/misa.png" alt="Michaela Studená" />
                </div>
                <div className="title">
                  <span className="name">Michaela Studená</span>
                  <br />
                  <span className="role">Managing Partner</span>
                  <br />
                  <span className="email">
                    <Link href="mailto:michaela@commlab.cz">michaela@commlab.cz</Link>
                  </span>
                  <br />
                  <span className="phone">
                    <Link href="tel:+420608307205">+420 608 307 205</Link>
                  </span>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="lead">
                <div className="frame">
                  <Li href="https://www.linkedin.com/in/jiri-durovic-69a77583/" />
                  <Mail href="mailto:jiri@commlab.cz" />
                  <img src="/jirka.png" alt="Jiří Ďurovič" />
                </div>
                <div className="title">
                  <span className="name">Jiří Ďurovič</span>
                  <br />
                  <span className="role">Managing Partner</span>
                  <br />
                  <span className="email">
                    <Link href="mailto:jiri@commlab.cz">jiri@commlab.cz</Link>
                  </span>
                  <br />
                  <span className="phone">
                    <Link href="tel:+420725997182">+420 725 997 182</Link>
                  </span>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="lead">
                <div className="frame">
                  <Li href="https://www.linkedin.com/in/janskalnik?originalSubdomain=cz" />
                  <Mail href="mailto:honza@commlab.cz" />
                  <img src="/honza.png" alt="Jan Skalník" />
                </div>
                <div className="title">
                  <span className="name">Jan Skalník</span>
                  <br />
                  <span className="role">Partner</span>
                  <br />
                  <span className="email">
                    <Link href="mailto:honza@commlab.cz">honza@commlab.cz</Link>
                  </span>
                  <br />
                  <span className="phone">
                    <Link href="tel:+420775000557">+420 775 000 557</Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '6em', transform: 'scale(0.8)' }}>
            <Col md={4}>
              <div className="lead">
                <div className="frame">
                  <Li href="https://www.linkedin.com/in/lucie-splichalova-piart/" />
                  <Mail href="mailto:lucie@commlab.cz" />
                  <img src="/lucie.png" alt="Lucie Šplíchalová" />
                </div>
                <div className="title">
                  <span className="name">Lucie Šplíchalová</span>
                  <br />
                  <span className="role">Managing Partner Piart Lab</span>
                  <br />
                  <span className="email">
                    <Link href="mailto:michaela@commlab.cz">lucie@commlab.cz</Link>
                  </span>
                  <br />
                  <span className="phone">
                    <Link href="tel:+420604150078 ">+420 604 150 078 </Link>
                  </span>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="lead">
                <div className="frame">
                  <Li href="https://www.linkedin.com/in/silvia-hud%C3%A1%C4%8Dkov%C3%A1-b13b141a" />
                  <Mail href="mailto:jiri@commlab.cz" />
                  <img src="/silva.png" alt="Silvia Hudáčková" />
                </div>
                <div className="title">
                  <span className="name">Silvia Hudáčková</span>
                  <br />
                  <span className="role">Managing Partner Commlab.sk</span>
                  <br />
                  <span className="email">
                    <Link href="mailto:jiri@commlab.sk">silvia@commlab.sk</Link>
                  </span>
                  <br />
                  <span className="phone">
                    <Link href="tel:+421907053346">+421 907 053 346</Link>
                  </span>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="lead">
                <div className="frame">
                  <Li href="https://www.linkedin.com/in/tom%C3%A1%C5%A1-klva%C5%88a-ph-d-19601a13a/" />
                  <Mail href="mailto:honza@commlab.cz" />
                  <img src="/tomas.png" alt="Tomáš Klvaňa" />
                </div>
                <div className="title">
                  <span className="name">Tomáš Klvaňa</span>
                  <br />
                  <span className="role">Managing Partner Commlab.sk</span>
                  <br />
                  <span className="email">
                    <Link href="mailto:tomas@commlab.sk">tomas@commlab.sk</Link>
                  </span>
                  <br />
                  <span className="phone">
                    <Link href="tel:+420734719889">+420 734 719 889</Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <h2>We are proud members</h2>
        <Container>
          <Row>
            <Col>
              <div className="partners-container">
                {partners.map((item, i) => (
                  <div className="partner-item" key={i}>
                    <a target="_blank" href={item.link}>
                      <img src={item.logo} />
                    </a>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
        <div className="contact">
          <Container>
            <Row>
              <Col>
                <h3>Contacts</h3>
                <p>In case of any questions, do not hesitate to contact us.</p>
              </Col>
            </Row>
            <Row className="contactFrame">
              <Col lg={2}></Col>
              <Col lg={4}>
                <p>
                  <AtIcon /> <Link href="mailto:michaela@commlab.cz">michaela@commlab.cz</Link> <br />
                  <PhoneIcon /> <Link href="tel:+420608307205">608 307 205</Link> <br />
                  <br />
                  <AtIcon /> <Link href="mailto:jiri@commlab.cz">jiri@commlab.cz</Link> <br />
                  <PhoneIcon /> <Link href="tel:+420725997182">725 997 182</Link> <br />
                </p>
                <p>
                  <FbIcon href="https://www.facebook.com/commlabcz/" />
                  <Li href="https://www.linkedin.com/company/commlab/" />
                </p>
              </Col>
              <Col lg={4}>
                <p>
                  <MapIcon />
                  <Link
                    target="_blank"
                    href="https://www.google.com/maps/place/%C5%A0aldova+34,+186+00+Karl%C3%ADn/@50.0944423,14.4530265,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94a423878733:0x808375bb9bc2a446!8m2!3d50.0944423!4d14.4552152"
                  >
                    Šaldova 34, Praha 8 – Karlín
                  </Link>
                  <br />
                  <br />
                  <strong>Billing addres:</strong>
                  <br />
                  Pekařova 838/2a, 181 00 Praha 8<br />
                  <br />
                  <strong>IČO:</strong>&nbsp; 03922367
                  <br />
                  <strong>DIČ:</strong>&nbsp; CZ03922367
                  <br />
                  <br />
                  <strong>Language</strong> <Link href="/">CZ</Link> | <Link href="/en">EN</Link>
                </p>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </Container>
        </div>
      </section>
      <footer>© {`${new Date().getFullYear()}`} Communication Lab, s. r. o. All rights reserved.</footer>
    </>
  );
};

export default IndexPage;
